var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "singledashboardstable"
  }, [_vm.fetchDashboardsError ? _c('r-error-message', {
    staticClass: "singledashboardstable--errormessage",
    attrs: {
      "errors": [_vm.fetchDashboardsError]
    }
  }) : _vm._e(), _c('r-table', {
    staticClass: "singledashboardstable--table",
    attrs: {
      "columns": _vm.tableColumns,
      "rows": _vm.dashboards,
      "total-rows": _vm.totalDashboards,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "loading": _vm.fetchingDashboards
    },
    on: {
      "change:page": function changePage(_ref) {
        var value = _ref.value;
        return _vm.setPage({
          page: value
        });
      },
      "change:page-size": _vm.onChangePageSize,
      "change:page-size-all": _vm.onChangePageSizeAll,
      "sort:asc": function sortAsc(_ref2) {
        var value = _ref2.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'asc'
        });
      },
      "sort:desc": function sortDesc(_ref3) {
        var value = _ref3.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'desc'
        });
      },
      "change:batch-select": function changeBatchSelect(data) {
        return _vm.$emit('change:batch-select', data);
      },
      "change:batch-select-all": function changeBatchSelectAll(data) {
        return _vm.$emit('change:batch-select-all');
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          attrs: {
            "to": "/dashboards/".concat(item._id)
          }
        }, [_c('div', {
          staticClass: "text-truncate",
          style: {
            width: "".concat(_vm.NAME_COLUMN_WIDTH, "px")
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])])];
      }
    }, {
      key: "item.type",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [[[_vm._v(" " + _vm._s(_vm.mutateValue(item.type)) + " ")], _c('v-icon', [_vm._v(" " + _vm._s(_vm.generateIcon(item.type)) + " ")])]];
      }
    }, {
      key: "item.brands",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.brands.length > 0 ? _c('span') : _vm._e(), _vm._l(item.brands.slice(0, 1), function (brand) {
          return _c('r-badge', {
            key: "".concat(item._id, "-").concat(brand._id),
            attrs: {
              "name": brand.name,
              "color": brand.color
            }
          });
        })];
      }
    }, {
      key: "item.categories",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.categories.length > 0 ? _c('span') : _vm._e(), _vm._l(item.categories.slice(0, 1), function (category) {
          return _c('r-badge', {
            key: "".concat(item._id, "-").concat(category._id),
            attrs: {
              "name": category.name,
              "color": category.color
            }
          });
        })];
      }
    }, {
      key: "item.tags",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.tags.length > 0 ? _c('span') : _vm._e(), _vm._l(item.tags.slice(0, 1), function (tag) {
          return _c('r-badge', {
            key: "".concat(item._id, "-").concat(tag._id),
            attrs: {
              "name": tag.name,
              "color": tag.color
            }
          });
        })];
      }
    }, {
      key: "item.numReviews",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', {
          staticClass: "d-flex justify-start align-center"
        }, [_c('span', [_vm._v(_vm._s(item.numReviews))])])];
      }
    }, {
      key: "item.lastReviewed",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.lastReviewed ? _c('span', [_vm._v(_vm._s(_vm.formatDate(item.lastReviewed)))]) : _c('span', [_vm._v("Never")])];
      }
    }, {
      key: "item.created",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")];
      }
    }, {
      key: "item.lastOpened",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [item.lastOpened ? _c('span', [_vm._v(_vm._s(_vm.formatDate(item.lastOpened)))]) : _c('span', [_vm._v("Never")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }