<template>
  <div class="singledashboardstable">
    <r-error-message
      v-if="fetchDashboardsError"
      class="singledashboardstable--errormessage"
      :errors="[fetchDashboardsError]"
    />
    <r-table
      class="singledashboardstable--table"
      :columns="tableColumns"
      :rows="dashboards"
      :total-rows="totalDashboards"
      :page="page"
      :page-size="pageSize"
      :loading="fetchingDashboards"
      @change:page="({ value }) => setPage({ page: value })"
      @change:page-size="onChangePageSize"
      @change:page-size-all="onChangePageSizeAll"
      @sort:asc="({ value }) => setSort({ sort: value, sortDir: 'asc' })"
      @sort:desc="({ value }) => setSort({ sort: value, sortDir: 'desc' })"
      @change:batch-select="(data) => $emit('change:batch-select', data)"
      @change:batch-select-all="(data) => $emit('change:batch-select-all')"
    >
      <template v-slot:item.name="{ item }">
        <router-link
          :to="`/dashboards/${item._id}`"
        >
          <div
            class="text-truncate"
            :style="{
              width: `${NAME_COLUMN_WIDTH}px`
            }"
          >
            {{ item.name }}
          </div>
        </router-link>
      </template>
      <template v-slot:item.type="{ item }">
        <template>
          <template>
            {{ mutateValue(item.type) }}
          </template>
          <v-icon>
            {{ generateIcon(item.type) }}
          </v-icon>
        </template>
      </template>
      <template v-slot:item.brands="{ item }">
        <span v-if="item.brands.length > 0" />
        <r-badge
          v-for="brand in item.brands.slice(0, 1)"
          :key="`${item._id}-${brand._id}`"
          :name="brand.name"
          :color="brand.color"
        />
      </template>
      <template v-slot:item.categories="{ item }">
        <span v-if="item.categories.length > 0" />
        <r-badge
          v-for="category in item.categories.slice(0, 1)"
          :key="`${item._id}-${category._id}`"
          :name="category.name"
          :color="category.color"
        />
      </template>
      <template v-slot:item.tags="{ item }">
        <span v-if="item.tags.length > 0" />
        <r-badge
          v-for="tag in item.tags.slice(0, 1)"
          :key="`${item._id}-${tag._id}`"
          :name="tag.name"
          :color="tag.color"
        />
      </template>
      <template v-slot:item.numReviews="{ item }">
        <div class="d-flex justify-start align-center">
          <span>{{ item.numReviews }}</span>
        </div>
      </template>
      <template v-slot:item.lastReviewed="{ item }">
        <span v-if="item.lastReviewed">{{ formatDate(item.lastReviewed) }}</span>
        <span v-else>Never</span>
      </template>
      <template v-slot:item.created="{ item }">
        {{ formatDate(item.created) }}
      </template>
      <template v-slot:item.lastOpened="{ item }">
        <span v-if="item.lastOpened">{{ formatDate(item.lastOpened) }}</span>
        <span v-else>Never</span>
      </template>
    </r-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RTable from '@/components/library/organisms/RTable'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import debounce from '@/utils/debounce'
import RBadge from '@/components/library/atoms/RBadge'
import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'
import { SINGLE_DASHBOARD_TYPE, SINGLE_DASHBOARD_ICONS } from '@/components/app/dashboards/constants'

const NAME_COLUMN_WIDTH = 250

export default {
  name: 'SingleTable',
  components: {
    RTable,
    RErrorMessage,
    RBadge,
  },
  props: {
    search: String,
  },
  data: () => ({
    NAME_COLUMN_WIDTH,
    tableColumns: [
      {
        key: 'name',
        label: 'Dashboard',
        hideable: true,
        sortable: true,
        width: NAME_COLUMN_WIDTH,
      },
      {
        key: 'type',
        label: 'Type',
        hidden: true,
        hideable: true,
        sortable: true,
        width: 150,
      },
      {
        key: 'numReviews',
        label: 'Reviews',
        hideable: true,
        sortable: true,
        width: 140,
      },
      {
        key: 'lastReviewed',
        label: 'Last reviewed',
        hideable: true,
        sortable: true,
        width: 140,
      },
      {
        key: 'lastOpened',
        label: 'Last Opened',
        hideable: true,
        sortable: true,
        width: 140,
      },
      {
        key: 'numUrls',
        label: 'URLs',
        hideable: true,
        sortable: true,
        width: 100,
      },
      {
        key: 'created',
        label: 'Creation date',
        hideable: true,
        sortable: true,
        width: 140,
      },
    ],
    debouncedFetchData: null,
  }),
  computed: {
    ...mapState('dashboards', [
      'dashboards',
      'fetchingDashboards',
      'fetchDashboardsError',
      'totalDashboards',
      'page',
      'pageSize',
      'sort',
      'sortDir',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
  },
  watch: {
    page() {
      this.$data.debouncedFetchData()
    },
    pageSize() {
      this.$data.debouncedFetchData()
    },
    search() {
      this.setPage({ page: 1 })
      this.$data.debouncedFetchData()
    },
    sort() {
      this.$data.debouncedFetchData()
    },
    sortDir() {
      this.$data.debouncedFetchData()
    },
    selectedProjectId() {
      this.$data.debouncedFetchData()
    },
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
    this.fetchData()
  },
  methods: {
    ...mapActions('dashboards', [
      'fetchDashboards',
      'setPage',
      'setPageSize',
      'setSort',
    ]),
    fetchData() {
      const {
        page,
        sort,
        sortDir,
        pageSize,
        selectedProjectId,
      } = this

      const { search } = this.$props
      let payload = {
        page, pageSize, search, projectId: selectedProjectId,
      }

      if (sort) {
        payload = { ...payload, sort, sortDir }
      }

      this.fetchDashboards(payload)
    },
    onChangePageSize({ value }) {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: value })
    },
    onChangePageSizeAll() {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: this.totalDashboards })
    },
    formatDate(date) {
      return formatDate({
        $moment: this.$moment,
        date: dateStrToDate(date),
      })
    },
    mutateValue(value) {
      return SINGLE_DASHBOARD_TYPE[value]
    },
    generateIcon(value) {
      return SINGLE_DASHBOARD_ICONS[value]
    },

  },
}
</script>

<style scoped>
.singledashboardstable {
  width: 100%;
}
.singledashboardstable--table {
  width: 100%;
}
</style>
