<template>
  <div class="dashboardslisting">
    <v-row class="page-header mb-16 mt-8">
      <v-col>
        <r-page-header
          class="dashboardslisting--pageheader"
          :label="$t('dashboards.dashboardsListing.title')"
        >
          <template v-slot:end>
            <r-button
              :label="$t('dashboards.addDashboard.title')"
              icon="add"
              @click="onClickAddDashboard"
            />
          </template>
        </r-page-header>
      </v-col>
    </v-row>

    <v-row class="ma-0 mt-16 pt-8">
      <v-col
        v-if="dashboards.length"
        cols="12"
        class="pa-0"
      >
        <r-error-message :errors="[deleteDashboardError]" />
        <v-row
          class="ma-0"
          justify="end"
        >
          <r-search-input
            :value="search"
            class="mr-2"
            style="width:300px;flex-grow:0;"
            @change="onChangeSearch"
          />
          <r-button
            outlined
            :label="$t('global.buttons.delete')"
            :disabled="disableDeleteButton"
            :loading="fetchingDeleteDashboard"
            style="height:32px"
            @click="onBatchDelete"
          />
        </v-row>
      </v-col>

      <v-col
        v-if="search"
        class="pa-0 dashboardslisting--chiprow"
        cols="12"
      >
        <r-chip
          removable
          :label="search"
          @click="search = ''"
        />
      </v-col>

      <v-col
        class="pa-2 pb-0"
        cols="12"
      >
        <single-dashboards-table
          v-if="selectedTab === 0"
          :search="search"
          @change:batch-select="onDashboardsBatchSelection"
          @change:batch-select-all="onDashboardsSelectAll"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RButton from '@/components/library/atoms/RButton'
import RPageHeader from '@/components/library/molecules/RPageHeader'
import RSearchInput from '@/components/library/molecules/RSearchInput'
import RChip from '@/components/library/atoms/RChip'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import SingleDashboardsTable from '@/components/app/dashboards/tables/SingleDashboardsTable'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'DashboardsListing',
  components: {
    RButton,
    RPageHeader,
    RSearchInput,
    RChip,
    SingleDashboardsTable,
    RErrorMessage,
  },
  data() {
    return {
      tabItems: [
        { label: this.$t('dashboards.dashboardsListing.single') },
        { label: this.$t('dashboards.dashboardsListing.comparisons') },
      ],
      selectedTab: 0,
      search: '',
      allDashboardsSelected: false,
      selectedDashboards: [],
    }
  },
  computed: {
    ...mapState('dashboards', [
      'dashboards',
      'fetchingDeleteDashboard',
      'deleteDashboardError',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    disableDeleteButton() {
      return !(this.allDashboardsSelected || this.selectedDashboards.length > 0)
    },
  },
  methods: {
    ...mapActions('dashboards', [
      'fetchDashboards',
      'clearErrors',
      'deleteDashboards',
    ]),
    onClickAddDashboard() {
      this.$router.push('/dashboards/add')
    },
    onChangeSearch({ value }) {
      this.$data.search = value
    },
    onDashboardsBatchSelection({ value: selectedItems }) {
      this.$data.allDashboardsSelected = false
      this.$data.selectedDashboards = selectedItems
    },
    onDashboardsSelectAll() {
      this.$data.allDashboardsSelected = true
    },
    async onBatchDelete() {
      this.clearErrors({ deleteDashboardError: '' })
      const basePayload = this.search ? { search: this.search } : {}
      let payload = { ...basePayload }

      if (!this.$data.allDashboardsSelected) {
        const selectedDashboardIds = this.$data.selectedDashboards.map(({ _id }) => _id)
        payload = { ...payload, dashboardIds: selectedDashboardIds }
      }

      await this.deleteDashboards(payload)

      if (!this.deleteDashboardError) {
        this.$data.selectedDashboards = []
        this.fetchDashboards({ ...basePayload, projectId: this.selectedProjectId })
      }
    },
  },
}
</script>

<style scoped>
/deep/ .rpageheader .rbutton {
  padding: 8px 60px !important;
}

</style>
